import Vue from "vue/dist/vue.esm";

// /components/usages
import AccordionForFooter from "components/AccordionForFooter.vue"

export default new Vue({
  el: "#footer-app",
  components: {
    AccordionForFooter
  }
});
