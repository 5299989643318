<template>
  <ul
    :class="ulMarginBottom">
    <li
      class="clickable-area"
      @click.self="open"
    >
      <span
        class="font-middle bold"
        @click.self="open"
      >
        {{ dropDownTitle }}
        <i
          class="material-icons float-right"
          :class="[arrowIconColor, { 'reverse-icon': isOpen }]"
          @click.self="open"
        >
          keyboard_arrow_down
        </i>
      </span>
      <transition
        name="accordion"
        @before-enter="beforeEnter"
        @enter="enter"
        @before-leave="beforeLeave"
        @leave="leave"
      >
        <ul
          v-show="isOpen"
          class="accordion-content"
        >
          <li
            v-for="item in dropDownContent"
            :key="item.contentName"
            :class="[contentMarginTop, contentPaddingLeft]"
          >
            <a
              :href="item.contentUrl"
              :class="[dropDownLinkColor, {'drop-down-link' : isLinkHoverOpacity}]"
              :target="item.isBlank ? '_blank' : false"
            >
              {{ item.contentName }}
            </a>
          </li>
        </ul>
      </transition>
    </li>
  </ul>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false
    }
  },
  props: {
    dropDownTitle: {
      type: String,
      default: ""
    },
    dropDownContent: {
      type: Array,
      default: [
        {
          contentName: "",
          contentUrl: "",
          isBlank: false
        }
      ]
    },
    dropDownLinkColor: {
      type: String,
      default: ""
    },
    isLinkHoverOpacity: {
      type: Boolean,
      default: false
    },
    arrowIconColor: {
      type: String,
      default: "text-gray03"
    },
    contentMarginTop: {
      type: String,
      default: "mt-3"
    },
    contentPaddingLeft: {
      type: String,
      default: ""
    },
    ulMarginBottom: {
      type: String,
      default: ""
    }
  },
  methods: {
    open() {
      this.isOpen = !this.isOpen;
    },
    beforeEnter(el) {
      el.style.height = '0';
    },
    enter(el) {
      el.style.height = el.scrollHeight + 'px';
    },
    beforeLeave(el) {
      el.style.height = el.scrollHeight + 'px';
    },
    leave(el) {
      el.style.height = '0';
    }
  }
}
</script>

<style lang="scss" scoped>
.drop-down-link {
  &:hover {
    opacity: .6;
  }
}
.clickable-area {
  &:hover{
    cursor: pointer;
  }
}
.reverse-icon{
  transform:rotate(180deg);
}

.accordion{
  &-content{
    transition: height .2s ease-in-out;
  }
  &-enter-active{
    animation-duration: .3s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
    animation-name: accordion-opend;
  }
  &-leave-active{
    animation-duration: .2s;
    animation-fill-mode: both;
    animation-name: accordion-closed;
  }
}

@keyframes accordion-opend {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes accordion-closed {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>
